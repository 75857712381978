import Modal, { ModalMixin } from "@/components/modals/layouts/Large.vue";
import Wrapper from "./Wrapper";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      limit: null
    };
  },
  async mounted() {
    await this.$store.dispatch("user/signOut");
  },
  methods: {
    onOpen({
      limit
    }) {
      if (typeof limit === "number") {
        this.limit = limit;
      }
    }
  },
  components: {
    Modal,
    Wrapper
  }
};