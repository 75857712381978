var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal"
  }, [_c('wrapper', {
    scopedSlots: _vm._u([{
      key: "alert",
      fn: function () {
        return [_vm._v(" Сессия завершена ")];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Выполнен вход с другого устройства ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('p', [_vm._v(" Мы разлогинили аккаунт, так как в него был выполнен вход с другого устройства ")]), _c('p', {
          staticClass: "mt16"
        }, [_vm._v(" Каждый аккаунт поддерживает " + _vm._s(_vm.limit) + " сессию одновременно ")])];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };